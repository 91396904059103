var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress-spacing"},[_c('div',{staticClass:"d-flex align-items-center lets_test_this"},[_c('div',{staticClass:"icon_wrap"},[(_vm.currentValue === 0)?_c('p',{staticClass:"new-back"},[_c('i',{staticClass:"fa margin-check fa-check"})]):_vm._e(),(_vm.currentValue === 0)?_c('div',{staticClass:"arrows-down"}):_vm._e(),_c('img',{attrs:{"src":require("@/assets/search-purple.png"),"alt":"","srcset":""}}),_c('p',{staticClass:"icon_text active-text"},[_vm._v("Explorer")])]),_c('div',{staticClass:"barWrapper"},[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar-one",style:({ width: `${_vm.currentValue}%` }),attrs:{"role":"progressbar"}},[_c('span',{staticClass:"popOver",attrs:{"id":"prog","data-toggle":"tooltip","data-placement":"top","data-trigger":"manual","title":`${_vm.currentValue}%`}}),(_vm.currentValue > 0)?_c('div',{ref:"tooltip",staticClass:"tooltip_box",class:{tooltip_box_talent : _vm.currentValue ===200},style:({
              left: `${
                _vm.currentValue < 100
                  ? _vm.currentValue - 12
                  : _vm.currentValue > 100 && _vm.currentValue < 200
                  ? _vm.currentValue + 10
                  : _vm.currentValue === 100
                  ? _vm.currentValue
                  : _vm.currentValue + 20
              }%`,
              top: `${
                _vm.currentValue < 50 ? null : _vm.currentValue > 50 ? -50 : -54
              }px`,
            })},[(_vm.currentValue < 100)?_c('span',{staticClass:"popOver custom_tooltip"},[_vm._v(_vm._s(_vm.currentValue / 2)+"%")]):_c('span',{staticClass:"popOver custom_tooltip",class:{
                talent__tooltip: _vm.currentValue > 100,
                'rising-star__tooltip': _vm.currentValue <= 100,
              }},[(_vm.currentValue > 100 && _vm.currentValue < 200)?_c('span',[_vm._v(_vm._s(_vm.currentValue / 2)+"%")]):_c('i',{staticClass:"fa margin-check fa-check"})]),_c('div',{staticClass:"arrows-down-progress",class:{
                'rising-star__arrow': _vm.currentValue === 100,
                talent__arrow: _vm.currentValue > 100,
              }})]):_vm._e()])])]),(_vm.currentValue < 100)?_c('div',{staticClass:"icon_wrap"},[_c('img',{attrs:{"src":require("@/assets/arrow-grey.png"),"alt":"","srcset":""}}),_c('p',{staticClass:"icon_text"},[_vm._v("Rising Star")])]):_c('div',{staticClass:"icon_wrap"},[_c('img',{attrs:{"src":require("@/assets/arrow-purple.png"),"alt":"","srcset":""}}),_c('p',{staticClass:"icon_text active_second"},[_vm._v("Rising Star")])]),_c('div',{staticClass:"barWrapper"},[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar-two",style:({ width: `${_vm.currentValue - 100}%` }),attrs:{"role":"progressbar"}},[_c('span',{staticClass:"popOver",attrs:{"data-toggle":"tooltip","data-placement":"top","title":"currentValue"}})])])]),(_vm.currentValue < 200)?_c('div',{staticClass:"icon_wrap"},[_c('img',{attrs:{"src":require("@/assets/star-grey.png"),"alt":"","srcset":""}}),_c('p',{staticClass:"icon_text"},[_vm._v("Talent")])]):_c('div',{staticClass:"icon_wrap"},[_c('img',{attrs:{"src":require("@/assets/star-purple.png"),"alt":"","srcset":""}}),_c('p',{staticClass:"icon_text active_second"},[_vm._v("Talent")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }